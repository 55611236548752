import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { AxiosError } from "../../../utils/api/axiosErrorType";
import { ChannelAPI } from "../../../utils/api/requests/channel-requests";
import { useParams } from "react-router-dom";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { getValue, needMultiply } from "../../../utils/inputTransform";
import { isValueBetween } from "../../../utils/isValueBetween";

interface UpdateNotificationInStartDayLimitModalProps {
  open: boolean;
  handleClose: () => void;
  refetch: () => void;
  value: string;
}

export const UpdateNotificationInStartDayLimitModal: FC<
  UpdateNotificationInStartDayLimitModalProps
> = ({ open, handleClose, value, refetch }) => {
  const { id } = useParams<Record<"id", string>>();
  const form = useForm<{ notification_in_start_day_limit: string }>({
    mode: "onChange",
  });
  const { handleSubmit, formState, control, reset } = form;

  const { isDirty, isValid } = formState;

  const { mutate, isLoading } = useMutation({
    mutationFn: ChannelAPI.updateNotificationInStartDayLimit,
    onSuccess: () => {
      refetch();
      toast.success("Лимит уведомлений успешно изменен");
      handleClose();
    },
    onError: (error) => {
      const err = error as AxiosError;
      toast.error(err?.response?.data?.detail);
    },
  });

  const onSubmit = async ({
    notification_in_start_day_limit,
  }: {
    notification_in_start_day_limit: string;
  }) => {
    mutate({ id: id!, notification_in_start_day_limit });
  };

  useEffect(() => {
    reset({ notification_in_start_day_limit: value });
  }, [reset, value]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <FormProvider {...form}>
        <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">Редактирование</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", width: "350px" }}
          >
            <Controller
              control={control}
              name="notification_in_start_day_limit"
              render={({
                field: { ref, onChange, value, ...field },
                fieldState: { error },
              }) => (
                <NumberFormat
                  customInput={TextField}
                  inputRef={ref}
                  onValueChange={({ floatValue }: NumberFormatValues) =>
                    onChange(floatValue !== undefined ? Number(floatValue) : "")
                  }
                  value={getValue(value, true, 1)}
                  type="text"
                  variant="outlined"
                  label={"Лимит уведомлений"}
                  error={!!error}
                  fullWidth={true}
                  decimalScale={2}
                  autoComplete="off"
                  allowNegative={false}
                  isAllowed={({ floatValue }) =>
                    floatValue === undefined ||
                    isValueBetween(floatValue, 0, 50)
                  }
                  min={0}
                  sx={{
                    marginTop: "12px",
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ marginBottom: "10px" }}>
            <Button variant="outlined" onClick={handleClose}>
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!isDirty || !isValid || isLoading}
            >
              Сохранить
            </Button>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  );
};
