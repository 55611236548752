import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ChannelAPI } from "../utils/api/requests/channel-requests";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getFormattedNumber } from "../utils/GetFormattedNumber";
import { ChartComponent } from "../ui/molecules/Chart";
import { Field } from "../ui/atoms/Field";
import { GetSubscribersColor } from "../utils/GetSubscribersColor";
import { OrderSection } from "../ui/molecules/OrderSection";
import { DescriptionField } from "../ui/atoms/DescriptionField";
import { useEffect } from "react";
import { queryClient } from "../..";
import { FolderField } from "../ui/atoms/FolderField";
import { NotificationMaxInDayLimitField } from "../ui/atoms/NotificationMaxInDayLimitField";
import { NotificationInStartDayLimitField } from "../ui/atoms/NotificationInStartDayLimitField";

export const Details = observer(() => {
  const { id } = useParams<Record<"id", string>>();
  const navigate = useNavigate();

  const { data, refetch, isLoading } = useQuery({
    queryFn: () => ChannelAPI.getChannel(id!),
    onError: () => {
      navigate("/channels");
    },
    queryKey: "channelData",
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    return () => {
      queryClient.resetQueries("channelData");
    };
  }, []);

  if (isLoading) {
    return (
      <Box
        height="100%"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flexGrow: 1,
          my: "200px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          marginBottom: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ArrowBackIosIcon
          fontSize="medium"
          style={{ transition: "all 0.2s linear" }}
          sx={{
            ml: "6px",
            mt: "4px",
            cursor: "pointer",
            "&:hover": { color: "#1976d2" },
          }}
          color="disabled"
          onClick={() => {
            navigate("/channels");
          }}
        />
        <Typography variant="h5">{data?.channel.name}</Typography>
      </Box>
      <Grid
        container={true}
        columns={2}
        sx={{
          ml: "16px",
          mt: "12px",
        }}
        spacing={"24px"}
      >
        <Field label={"Название канала"} value={data?.channel.name} />
        <Field label={"Имя пользователя"} value={data?.channel.username} />
        <Field label={"Статус"} value={data?.channel.status.title} />
        <Field
          label={"Количество подписчиков"}
          value={getFormattedNumber(data?.channel.subscribers!) as string}
        />
        <DescriptionField
          label={"Описание канала"}
          value={data?.channel.comment || "-"}
          refetch={refetch}
        />
        <FolderField
          label={"Папка"}
          refetch={refetch}
          channel={data?.channel!}
        />
        <NotificationMaxInDayLimitField
          label={"Лимит уведомлений от максимального за день, %"}
          refetch={refetch}
          value={data?.channel.notification_max_in_day_limit}
        />
        <NotificationInStartDayLimitField
          label={"Лимит уведомлений от начала дня, %"}
          refetch={refetch}
          value={data?.channel.notification_in_start_day_limit}
        />
      </Grid>
      <Box>
        <Typography sx={{ ml: "40px", mt: "32px", fontSize: "24px" }}>
          Статистика
        </Typography>
        <Grid
          container={true}
          columns={2}
          sx={{
            ml: "16px",
            mt: "12px",
          }}
          spacing={"24px"}
        >
          <Grid
            item={true}
            xs={1}
            sx={{
              width: "200px",
              height: "150px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <Box display={"flex"}>
              <Typography>Сегодня:</Typography>
              <Typography
                sx={{ ml: "4px" }}
                color={GetSubscribersColor(data?.subscribers_stats.day!)}
              >
                {data?.subscribers_stats.day || "-"}
              </Typography>
            </Box>
            <Box display={"flex"}>
              <Typography>За 3 дня:</Typography>
              <Typography
                sx={{ ml: "4px" }}
                color={GetSubscribersColor(data?.subscribers_stats.three_day!)}
              >
                {data?.subscribers_stats.three_day || "-"}
              </Typography>
            </Box>
            <Box display={"flex"}>
              <Typography>За неделю:</Typography>
              <Typography
                sx={{ ml: "4px" }}
                color={GetSubscribersColor(data?.subscribers_stats.week!)}
              >
                {data?.subscribers_stats.week || "-"}
              </Typography>
            </Box>
            <Box display={"flex"}>
              <Typography>За месяц:</Typography>
              <Typography
                sx={{ ml: "4px" }}
                color={GetSubscribersColor(data?.subscribers_stats.month!)}
              >
                {data?.subscribers_stats.month || "-"}
              </Typography>
            </Box>
          </Grid>
          <Grid item={true} xs={1} sx={{ width: "200px", height: "150px" }}>
            <ChartComponent Chart={data?.subscribers_stats.chart!} />
          </Grid>
        </Grid>
      </Box>
      <OrderSection ordersArray={data?.orders!} refetch={refetch} />
    </>
  );
});
