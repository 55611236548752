import { Channel, ChannelData, Folder, Log, Order } from "../../../types";
import { get, patch, post, remove } from "../axiosConfig";

export const ChannelAPI = {
  async getChannelsList({
    folder_id,
    sort_direction,
  }: {
    folder_id?: number;
    sort_direction?: "asc" | "desc";
  }): Promise<Channel[]> {
    const { data } = await get("/api/v1/channels", {
      params: {
        folder_id,
        sort: "subscribers",
        sort_direction,
      },
    });
    return data;
  },
  async addChannel({
    channel_link,
    folder_id,
  }: {
    channel_link: string;
    folder_id: number;
  }): Promise<true> {
    const { data } = await post("/api/v1/channels", {
      channel_link,
      folder_id,
    });
    return data;
  },
  async deleteChannel(id: number): Promise<true> {
    const { data } = await remove(`/api/v1/channels/${id}`);
    return data;
  },
  async getChannel(id: string): Promise<ChannelData> {
    const { data } = await get(`/api/v1/channels/${id}`);
    return data;
  },
  async getOrders(): Promise<Order[]> {
    const { data } = await get("/api/v1/orders");
    return data;
  },
  async updateOrders({
    payload,
    id,
  }: {
    id: string;
    payload: {
      value: number | null;
      type: string;
      orders: number[];
      date: string;
      interval: number;
      anti_minus: number | null | string;
    }[];
  }): Promise<Order[]> {
    const { data } = await patch(`/api/v1/channels/${id}/orders`, [...payload]);
    return data;
  },
  async updateComment({
    comment,
    id,
  }: {
    comment: string;
    id: string;
  }): Promise<true> {
    const { data } = await patch(`/api/v1/channels/${id}/comment`, {comment});
    return data;
  },

  async getChannelLogs({
    id,
    toDate,
    fromDate,
    limit,
  }: {
    id: string;
    toDate?: Date;
    fromDate?: Date;
    limit: number;
  }): Promise<{
    logs: Log[];
    count: number;
    completed: number;
    failed: number;
  }> {
    const params = { min_date: fromDate, max_date: toDate, limit };
    const { data } = await get(`/api/v1/channels/${id}/logs`, { params });
    return data;
  },
  async addFolder(name: string): Promise<true> {
    const { data } = await post("/api/v1/folders", { name });
    return data;
  },
  async getFoldersList(): Promise<Folder[]> {
    const { data } = await get("/api/v1/folders");
    return data;
  },
  async updateChannelFolder({
    folder_id,
    id,
  }: {
    folder_id: number;
    id: number;
  }): Promise<true> {
    const { data } = await patch(`/api/v1/channels/${id}/folder`, {folder_id});
    return data;
  },
  async deleteFolder(id: number): Promise<true> {
    const { data } = await remove(`/api/v1/folders/${id}`);
    return data;
  },
  async updateFolder({
    name,
    id,
  }: {
    name: string;
    id: number;
  }): Promise<true> {
    const { data } = await patch(`/api/v1/folders/${id}`, { name });
    return data;
  },
  async updateNotificationMaxInDayLimit({
    notification_max_in_day_limit,
    id,
  }: {
    notification_max_in_day_limit: string;
    id: string;
  }): Promise<true> {
    const { data } = await patch(
      `/api/v1/channels/${id}/notification-max-in-day-limit`,
        {notification_max_in_day_limit}
    );
    return data;
  },
  async updateNotificationInStartDayLimit({
    notification_in_start_day_limit,
    id,
  }: {
    notification_in_start_day_limit: string;
    id: string;
  }): Promise<true> {
    const { data } = await patch(
      `/api/v1/channels/${id}/notification-in-start-day-limit`,
        {notification_in_start_day_limit}
    );
    return data;
  },
};
